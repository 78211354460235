@import url("https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300&display=swap");

:root {
  --primary-color: #055a5b;
  --background-color: #f9dcc4;
}
body,
html {
  font-family: "Ubuntu", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  transition: scroll-behavior 0.3s ease-in-out;
}

.ant-menu-item {
  border: 1px solid transparent;
  line-height: 40px;
  border-radius: 4px !important;
  vertical-align: middle;
  font-weight: 500;
}

.ant-menu-item-selected {
  color: #055a5b !important;
}

.ant-menu-item:hover {
  border: 1px solid #055a5b;
  border-radius: 4px !important;
  transition: border 0.25s ease-in-out;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: none !important;
  transition: none !important;
}

.layout {
  justify-content: center;
}

.active-row {
  justify-content: center;
  background-color: var(--background-color);
  font-family: "Ubuntu", sans-serif;
}

.dark-row {
  justify-content: center;
  background-color: #344e41;
  font-family: "Ubuntu", sans-serif;
  background: radial-gradient(
        circle,
        transparent 20%,
        #344e41 20%,
        #344e41 80%,
        transparent 80%,
        transparent
      )
      0% 0% / 80px 80px,
    radial-gradient(
        circle,
        transparent 20%,
        #344e41 20%,
        #344e41 80%,
        transparent 80%,
        transparent
      )
      40px 40px / 80px 80px,
    linear-gradient(#f9dcc4 0.5px, transparent 0.5px) 0px -0.25px / 40px 40px,
    linear-gradient(90deg, #f9dcc4 0.5px, #344e41 0.5px) -0.25px 0px / 40px 40px
      #344e41;
  background-size: 80px 80px, 80px 80px, 40px 40px, 40px 40px;
}

.dark-min-row {
  justify-content: center;
  background-color: #344e41;
  font-family: "Ubuntu", sans-serif;
}

.black-row {
  justify-content: center;
  background-color: #160c11;
}

.lighter-row {
  justify-content: center;
  background-color: #fef1e5;
}

.header-button {
  background-color: "transparent" !important;
  text-align: center;
  vertical-align: middle;
  color: #64451a;
  border: 1px solid #64451a;
  font-weight: 500;
  border-radius: 0px;
}

.header-button:hover {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  font-weight: 500;
  border-color: var(--primary-color) !important;
}

.card {
  position: relative;
  background: white;
  background-image: url("./assets/kosmos-empower.jpg");
  transform-style: preserve-3d;
  border: 1px solid black;
  width: 100%;
  height: 100%;
  background-size: cover;
}

.card:hover {
  content: url("./assets/kosmos.png");
}

.card::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: radial-gradient(
        farthest-side at -33.33% 50%,
        #0000 52%,
        #f9dcc4 54% 57%,
        #0000 59%
      )
      0 calc(76px / 2),
    radial-gradient(
        farthest-side at 50% 133.33%,
        #0000 52%,
        #f9dcc4 54% 57%,
        #0000 59%
      )
      calc(76px / 2) 0,
    radial-gradient(
      farthest-side at 133.33% 50%,
      #0000 52%,
      #f9dcc4 54% 57%,
      #0000 59%
    ),
    radial-gradient(
      farthest-side at 50% -33.33%,
      #0000 52%,
      #f9dcc4 54% 57%,
      #0000 59%
    ),
    #055a5b;
  background-size: calc(76px / 4.667) 76px, 76px calc(76px / 4.667);
  bottom: -10px;
  left: -10px;
  border: 1px solid black;
  transform: translateZ(-1px);
  box-sizing: content-box; /* Only if you have some reset that makes everything border-box */
}

.profile {
  position: relative;
  background: white;
  transform-style: preserve-3d;
  border: 1px solid black;
  width: 100%;
  height: 100%;
  background-size: contain;
}

.profile::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;

  background: radial-gradient(
        farthest-side at -33.33% 50%,
        #0000 52%,
        #f9dcc4 54% 57%,
        #0000 59%
      )
      0 calc(76px / 2),
    radial-gradient(
        farthest-side at 50% 133.33%,
        #0000 52%,
        #f9dcc4 54% 57%,
        #0000 59%
      )
      calc(76px / 2) 0,
    radial-gradient(
      farthest-side at 133.33% 50%,
      #0000 52%,
      #f9dcc4 54% 57%,
      #0000 59%
    ),
    radial-gradient(
      farthest-side at 50% -33.33%,
      #0000 52%,
      #f9dcc4 54% 57%,
      #0000 59%
    ),
    #344e41;
  background-size: calc(76px / 4.667) 76px, 76px calc(76px / 4.667);
  bottom: -10px;
  left: -10px;
  border: 1px solid white;
  transform: translateZ(-1px);
  box-sizing: content-box; /* Only if you have some reset that makes everything border-box */
}

.module {
  position: relative;
  background: white;
  transform-style: preserve-3d;
  border: 1px solid black;
  width: 100%;
  height: 100%;
  background-size: contain;
}

.module::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;

  background: radial-gradient(
        farthest-side at -33.33% 50%,
        #0000 52%,
        #f9dcc4 54% 57%,
        #0000 59%
      )
      0 calc(76px / 2),
    radial-gradient(
        farthest-side at 50% 133.33%,
        #0000 52%,
        #f9dcc4 54% 57%,
        #0000 59%
      )
      calc(76px / 2) 0,
    radial-gradient(
      farthest-side at 133.33% 50%,
      #0000 52%,
      #f9dcc4 54% 57%,
      #0000 59%
    ),
    radial-gradient(
      farthest-side at 50% -33.33%,
      #0000 52%,
      #f9dcc4 54% 57%,
      #0000 59%
    ),
    #344e41;
  background-size: calc(76px / 4.667) 76px, 76px calc(76px / 4.667);
  bottom: -10px;
  left: -10px;
  border: 1px solid white;
  transform: translateZ(-1px);
  box-sizing: content-box; /* Only if you have some reset that makes everything border-box */
}

.price {
  position: relative;
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  background-size: contain;
}

.price:hover::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;

  background: radial-gradient(
        farthest-side at -33.33% 50%,
        #0000 52%,
        #f9dcc4 54% 57%,
        #0000 59%
      )
      0 calc(76px / 2),
    radial-gradient(
        farthest-side at 50% 133.33%,
        #0000 52%,
        #f9dcc4 54% 57%,
        #0000 59%
      )
      calc(76px / 2) 0,
    radial-gradient(
      farthest-side at 133.33% 50%,
      #0000 52%,
      #f9dcc4 54% 57%,
      #0000 59%
    ),
    radial-gradient(
      farthest-side at 50% -33.33%,
      #0000 52%,
      #f9dcc4 54% 57%,
      #0000 59%
    ),
    #344e41;
  background-size: calc(76px / 4.667) 76px, 76px calc(76px / 4.667);
  bottom: -10px;
  left: -10px;
  border-radius: 0% 25% 0% 0% / 25% 26% 25% 25%;
  border: 1px solid white;
  transform: translateZ(-1px);
  box-sizing: content-box; /* Only if you have some reset that makes everything border-box */
}

@media (max-width: 768px) {
  .profile-cards {
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  .profile-card {
    margin-right: 16px;
    margin-left: 16px;
  }
}

@media (max-width: 768px) {
  .overview-overflow {
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  .overview-overflow {
    margin-right: 16px;
    margin-left: 16px;
  }
}

@media (max-width: 768px) {
  .price-overflow {
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
  }

  .price-overflow {
    margin-right: 16px;
    margin-left: 16px;
  }
}

.profile-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px; /* Add desired space between cards */
}

.profile-card {
  flex: 1;
  min-width: 300px; /* Adjust based on the desired minimum width */
  /* Add your Profile Card styling */
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .container {
    justify-content: center;
  }
}

.header {
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent !important;
  transition: background-color 0.1s ease-in-out !important;
  z-index: 9999;
  display: flex;
  justify-content: 'space-between';
  align-items: center;
  padding-left: 0;
  padding-right: 0;
}

.header.white {
  position: fixed;
  background-color: #fff !important;
}
